import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { WalletDisconnectButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)``;

const DisconnectButton = styled(WalletDisconnectButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate*1000));
  

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
      setIsActive(false);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>
      <div id="walletBar">
        <span id="walletKey">
          {wallet && (
            <DisconnectButton>
              Click to Disconnect Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}
            </DisconnectButton>
          )}
        </span>


      </div>
      <div id="main">

        <div id="header">
          <h1><img className="pigHeader" src="./pig.png" />&nbsp;<img className="pigHeader" src="./pig.png" />&nbsp;&nbsp;
            PiggyPals NFT: Mint your Pal!&nbsp;&nbsp;
            <img className="pigHeader" src="./pig.png" />&nbsp;<img className="pigHeader" src="./pig.png" />
          </h1>

          <span className="headerInfo">


            Evelyn, Elias, and Wesley (ages 7, 6, and 3) collaborate with Dad on a fun art project! Join the <a className="mx-20" href="https://discord.gg/GsPg7ewPSa" target="_blank" rel="noreferrer">Discord</a> or the conversation on <a href="https://twitter.com/piggypalsNFT" target="_blank" rel="noreferrer">Twitter</a> around developing shared interests and making memories with your kids!
            <br /><br />
            <img className="artistsImg" src="./piggypals-team.jpg" /><br />
            <span className="imgcaption">Camping in the basement!</span>
            <br /><br />
            Connect your wallet to grab 1 of 763 unique, friendly pigs on Solana for just 0.1 SOL!
            <br /><br />
            {/*<span className="mintstartdate">Minting begins Nov 20, 2021 at 9am PST (5pm UTC)</span>*/}
            {/*<span className="mintstartdate">Minting date will be announced soon!</span> */}
            { /*`<span className="mintstartdate">Minting begins ${startDate}</span>` */ }
          </span>
        </div>
        <br />

        
 

        <div id="mintInfo">
          <MintContainer>
            <span className="mintstartdate">Minting is LIVE!</span><br />
            {wallet ? (
              <div id="mintStats">
                <p>{wallet && itemsRedeemed} Piggy Pals have been minted.</p>
                <p>{wallet && itemsRemaining} / {wallet && itemsAvailable} remaining.</p>
                <span id="lowBalanceWarn">{wallet && ((balance || 0) < 0.101) ? ('Insufficient SOL in wallet - at least 0.1 required to mint!') : ""}</span>

              </div>

            ) : ""}

            {!wallet ? (
              <ConnectButton>Connect Solana Wallet</ConnectButton>
            ) : (
              <MintButton
                disabled={isSoldOut || isMinting || !isActive}
                onClick={onMint}
                variant="contained"
              >
                {isSoldOut ? (
                  "SOLD OUT"
                ) : isActive ? (
                  isMinting ? (
                    <CircularProgress />
                  ) : (
                    "MINT ME A PIGGYPAL!"
                  )
                ) : (
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => setIsActive(true)}
                    renderer={renderCounter}
                  />
                )}
              </MintButton>
            )}
            <br />
            <div id="inlineAlert">

              <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({ ...alertState, open: false })}
              >
                <Alert
                  onClose={() => setAlertState({ ...alertState, open: false })}
                  severity={alertState.severity}
                >
                  {alertState.message}
                </Alert>
              </Snackbar>
            </div>
          </MintContainer>
        </div>
     
     
        <div id="howToMint">
          <h3>Meet the Artists</h3>
          <img className="artistImg" src="./piggypals-artist-evelyn.jpg" />
          <br />
          <span className="imgcaption">Evelyn "the Instigator", age 7</span><br />
          <span>Inspired by the adorable artwork of the Pudgy Penguins collection, and Abigail, the creator of Belugies, Evelyn wanted to draw a set of her own characters and decided pigs would be fun!</span>
          <br /><br />
          <img className="artistImg" src="./piggypals-artist-elias.jpg" />
          <br />
          <span className="imgcaption">Elias "the Accomplice", age 6</span><br />
          <span>Responsible for some of the crazier attributes like the rainbow plane background and the astronaut suit, he made the project a non-stop giggle-fest.</span>
          <br /><br />
          <img className="artistImg" src="./piggypals-artist-wes.jpg" />
          <br />
          <span className="imgcaption">Wes "the Manipulator", age 3</span><br />
          <span>He’s the baby and he knows it’s a free pass to get away with just about anything. "Please, Dad, I draw next?" Demonstrating his fine motor skills, his handiwork includes the green eyes, most of the mouths, and, of course, his signature.</span>
          <br /><br />
          <img className="artistImg" src="./piggypals-8.png" />
          <br />
          <h3>What's Next?</h3>
          <ul>
            <li>Piggy Pals is all about discovering and nurturing shared interests between kids and parents.  Minting and displaying your Piggy Pals NFT is a symbol of support for that important and irreplaceable bond.</li>
            <li>We’d love to connect and build a community with other families who are being intentional about introducing their children to their interests, whether that’s art and technology, or sports, gardening, music, cooking, etc.</li>
            <li>20 % of the proceeds will be donated to local charities near and dear to our family’s heart: 
              <ul>
                <li>10% to Doernbecher Children’s Hospital (Pediatric Cancer Care and Research)</li>
                <li>10% to Portland Rescue Mission (fighting hunger, homelessness, and addiction)</li>
                <li>We (artists' mom and dad) will also be matching the donation, for a total donation of 40% of the proceeds!</li>
              </ul>
            </li>
            
            <li>The remaining proceeds from the mint and royalties will be split between the 3 artists and used to teach them about money management, investing, and staking SOL.</li>
            <li>At least daily since the last attribute was drawn, the artists have asked to do another art project, stay tuned for more fun collaborations from the crew!</li>
          </ul>

          <h3>Action shots</h3>
          <img className="artistsImg" src="./piggypals-work1.jpg" /><br />
          <img className="artistsImg" src="./piggypals-work3.jpg" /><br />
          <img className="artistsImg" src="./piggypals-work2.jpg" /><br />

          <h3>FAQ</h3>

          <h4>What is Piggy Pals?</h4>
Piggy Pals is an artwork collection consisting of 763 generative NFTs (Non-Fungible Tokens) on the Solana blockchain. Each NFT is a 1-of-1 image with its own unique set of attributes.  Some attributes are more common, while others are quite rare!
<h4>What is an NFT "mint"?</h4>
"Minting" an NFT selects the next available NFT from the collection (e.g. PiggyPals #23) and makes it yours, storing the proof of that ownership in your blockchain wallet.  Once that happens, because the collection is entirely 1-of-1 uniques, no one else will own that exact same work of art.
<h4>How do I mint a fresh Piggy Pals NFT?</h4>
<ol>
<li>Ensure you have at least 0.101 SOL in your Solana wallet.  We recommend Phantom Wallet for the ease of use, AND it displays your NFTs right there in the wallet. It’s a good idea to not have too much more in the wallet either when participating in mints, and most folks mint with a fresh wallet with just enough SOL for the transaction.</li>
<li>Connect your Solana wallet using the “Connect Solana Wallet” button at the top of this page. </li>
<li>After the mint countdown strikes 0, click the "Mint a Piggy Pal" button.</li>
<li>Approve the transaction in your wallet</li>
<li>If there are still NFTs remaining, one will be minted directly into your wallet! If not, your SOL will remain in your wallet.</li>
</ol>
<h4>What can I do with my Piggy Pals NFT?</h4>
After you mint or purchase a Piggy Pal, it will be stored in the same wallet.  You can keep, trade, or sell your NFT, and you’re free to use the image however you like under a non-exclusive license.
<h4>How much for a Piggy Pals NFT?</h4>
The mint price is set at 0.1 SOL, which includes permanent storage of your NFT on Arweave and the mint network fee.
<h4>What happens after all 763 NFTs are minted?</h4>
The mint will automatically close, no more Piggy Pals NFTs will be created, and any future purchases will need to be from another community member.  We recommend using a reputable marketplace like MagicEden to transact securely.
<br /><br />
And, of course, we’ll have to do something special to celebrate, won’t we!?  We’d love to hear your ideas in the discord.
<h4>Where can I trade the Piggy Pals NFTs after they’re minted?</h4>
We’re working to establish a verified collection on the major marketplaces like DigitalEyes.market, MagicEden.io, Solanart.io, and Solsea.io.
<h4>Why 763 NFTs?</h4>
The artists ages: 7, 6, and 3!
<h4>What is the Signature Series?</h4>
Each artist’s signature was randomly added to 20% of the NFTs. A fraction of the total collection will have 2 of the 3 artists’ signatures, and less than 1% will have all 3 signatures.  Rare indeed!
<h4>I’d love to do a project like this with my kid(s) – how do I get started?</h4>
<ul><li>First of all, that’s awesome and we hope you have as much fun as we did!  Keep us posted in the #our-projects channel in the <a className="mx-20" href="https://discord.gg/GsPg7ewPSa" target="_blank" rel="noreferrer">Discord</a>!</li>
<li>Check out the following tutorials to get an idea of the process, and then follow the rabbit hole:
  <ul>
    <li><a className="mx-20" href="https://www.youtube.com/watch?v=UCxA8sDltMY" target="_blank" rel="noreferrer">Creating the generative art collection</a></li>
    <li><a className="mx-20" href="https://www.youtube.com/watch?v=35RO0lAEIxE" target="_blank" rel="noreferrer">Prepare the mint (on Solana, of course!) </a></li>
  </ul>
</li>
<li>Keep in mind, the whole space is in its infancy, with trends and tools evolving every day!</li>
</ul>
          

          
        </div>

        <div className="social">
          <a href="https://twitter.com/piggypalsNFT" target="_blank" rel="noreferrer">
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40"><path fill="#FFF" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
          </a>
          <a className="mx-20" href="https://discord.gg/GsPg7ewPSa" target="_blank" rel="noreferrer">
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="40"><path fill="#FFF" d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>
          </a>
        </div>

        <div className="disclaimer">
          Gm. For the avoidance of doubt, PiggyPals NFT is an art project, created for entertainment value, and is not an investment or financial product.  No economic return is expressed or implied.  Nothing on piggy-pals.com, the PiggyPals Discord, PiggyPals Twitter, or communication with the creators should be considered financial advice.  No refunds will be provided.  Gn.
        </div>

      </div>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
      <div id="footer">

      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
